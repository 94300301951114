import React from 'react';
import ExplainerItem from '../components/explaineritem/Item';
import { ReactComponent as UnLock } from 'assets/stepper/unlock.svg';
import { ReactComponent as Star } from 'assets/stepper/star.svg';
import explainerItemStyles from '../components/explaineritem/styles.module.scss';
import { ExplainerWrapper } from '../components/explainerwrapper/Wrapper';

export const TrialExplainerWithHeighlight = () => {
  return (
    <ExplainerWrapper>
      <ExplainerItem
        className={explainerItemStyles.lock}
        icon={<UnLock />}
        title="Today"
        description="Your trial begins. Enjoy full access to all the premium content."
      />
      <ExplainerItem
        className={explainerItemStyles.heightlight}
        title="7 days of full access"
        description="You can cancel anytime before your trial ends."
      />
      <ExplainerItem
        className={explainerItemStyles.star}
        icon={<Star />}
        title="Day 7"
        description="Your trial will convert to full price unless cancelled."
      />
    </ExplainerWrapper>
  );
};
