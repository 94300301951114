import React, { useRef } from 'react';
import { useAppDispatch } from 'bootstrap/hooks';
import { Footer } from 'components/footer/Footer';
import {
  isFacebookReviews,
  isFaq,
  isFreeVsPaid,
  isFeaturedInFlow,
  isIgnoreSkipTrialFlow,
  isPaymentPlanSelectorDirect,
} from 'navigation';
import { pushWithSearchQuery } from 'utils';
import { Information } from 'components/information/Information';
import { Recurly } from 'subscription/recurly/Recurly';
import { Reviews } from 'subscription/components/reviews/Reviews';
import { TrialOffer } from 'subscription/recurly/components/trialoffer/TrialOffer';
import { FacebookReviews } from 'subscription/components/reviews/FacebookReviews';
import { Product } from 'bootstrap/data/product/Product';
import { ReasonToUseTemplate } from '../components/reasontemplate/ReasonToUseTemplate';
import { ManualInfoTemplate } from '../components/manualtemplate/ManualInfoTemplate';
import { PaymentFormTemplate } from '../components/paymentformtemplate/PaymentFormTemplate';
import { Header } from '../components/header/Header';
import { FreePaidTable } from './components/freepaidtable/FreePaidTable';
import { FaqAccordion } from './components/faq/FaqAccordion';
import Divider from '../../components/divider/Divider';
import { useSingleProductLogic } from './SingleProductPayment.hook';
import { FeatureSection } from 'subscription/components/featuresection/FeatureIn';
import { PlanSection } from './components/plansection/PlanSection';
import { PlanSectionWrapper } from './components/plansection/PlanSectionWrapper';
import { PlanSelectType } from './components/plansection/PlanSection.constants';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { MarketInfo } from 'subscription/recurly/components/marketinfo/MarketInfo';
import styles from './styles.module.scss';

type Props = {
  defaultProduct: Product;
  products: Map<string, Product>;
};

export const SingleProductPayment = ({ defaultProduct, products }: Props) => {
  const dispatch = useAppDispatch();
  const paymentFormRef = useRef<HTMLDivElement>(null);

  const {
    authToken,
    currencyCode,
    isTrialOfferShown,
    product,
    weekProduct,
    monthProduct,
    quarterProduct,
    planType,
    handleGetPlanClick,
  } = useSingleProductLogic({
    defaultProduct,
    products,
    paymentFormRef,
  });

  if (!authToken) {
    dispatch(pushWithSearchQuery('/create-account'));
    return null;
  }

  if (
    isTrialOfferShown &&
    (!isIgnoreSkipTrialFlow() || !isPaymentPlanSelectorDirect())
  ) {
    return <TrialOffer defaultProduct={product} isTrialOfferVisible={true} />;
  }

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY || ''}
      scriptProps={{
        async: false,
        defer: false,
        appendTo: 'head',
        nonce: undefined,
      }}
    >
      <div className={styles.payment}>
        <div
          className={`
          ${styles.paymentContainer} 
          ${styles.bottom90}
        `}
        >
          <Header />

          {!isPaymentPlanSelectorDirect() && !!product.trialLength && (
            <ManualInfoTemplate product={product} />
          )}

          {isFeaturedInFlow() && <FeatureSection />}

          {!isPaymentPlanSelectorDirect() && (
            <PaymentFormTemplate
              product={product}
              presaleProduct={product}
              currencyCode={currencyCode}
              handleGetPlanClick={handleGetPlanClick}
            />
          )}

          {isPaymentPlanSelectorDirect() && (
            <PlanSectionWrapper
              handleGetPlanClick={handleGetPlanClick}
              product={product}
            >
              <PlanSection
                type={PlanSelectType.WEEK}
                isManual={true}
                currencyCode={currencyCode}
                product={weekProduct}
                isRenewPriceVisible={false}
                isSelected={planType === PlanSelectType.WEEK}
                label=""
              />
              <PlanSection
                type={PlanSelectType.MONTH}
                currencyCode={currencyCode}
                isRenewPriceVisible={true}
                product={monthProduct}
                isSelected={planType === PlanSelectType.MONTH}
                label="most popular"
              />
              <PlanSection
                type={PlanSelectType.QUARTER}
                currencyCode={currencyCode}
                isRenewPriceVisible={true}
                product={quarterProduct}
                isSelected={planType === PlanSelectType.QUARTER}
              />
            </PlanSectionWrapper>
          )}

          {isFreeVsPaid() ? (
            <FreePaidTable product={product} />
          ) : (
            <ReasonToUseTemplate withDivider={false} />
          )}

          <Divider isDividerWithMargin />

          {isFacebookReviews() ? <FacebookReviews /> : <Reviews />}

          {!isFacebookReviews() && <Divider />}

          {isFaq() && <FaqAccordion />}

          <Information />

          <div ref={paymentFormRef}>
            <Recurly product={product} />
          </div>

          <MarketInfo product={product} />

          <Footer className={styles.footer} />
        </div>
      </div>
    </GoogleReCaptchaProvider>
  );
};
