import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useRecurly } from '@recurly/react-recurly';
import { appAnalytics } from 'analytics';
import { CardFormInputStarted, Subscribe } from 'analytics/AllEvents';
import { Product } from 'bootstrap/data/product/Product';
import { useAppDispatch, useAppSelector } from 'bootstrap/hooks';
import { createSubscription } from 'bootstrap/net/subscription';
import { setTrialOfferShown } from 'bootstrap/user/actions';
import { SuccessPaymentProps } from 'subscription/recurly/Recurly.types';
import {
  isFormFieldNotEmpty,
  isFullNameValid,
  isPostalCodeShown,
} from '../layoutcardform/LayoutCardForm.utils';
import { pushWithSearchQuery } from 'utils';
import { isWorkbookUpsellFlow } from 'navigation';
import { setSubscriptionToken } from 'bootstrap/user/actions/setUserInfo';
import { withRecaptcha } from '../../withRecaptcha/withRecaptcha';

type Props = {
  product: Product;
  onSuccess: (params: SuccessPaymentProps) => void;
  onError: (message: string) => void;
};

export const useLayoutCardFormLogic = ({
  product,
  onSuccess,
  onError,
}: Props) => {
  const recurly = useRecurly();
  const dispatch = useAppDispatch();

  const { countryCode, currency, subscriptionToken, paymentMethod } =
    useAppSelector((state) => state.user);

  const isPostalCodeVisible = isPostalCodeShown(countryCode);

  const formRef = useRef<HTMLFormElement>(null);
  const postalCodeRef = useRef<HTMLInputElement>(null);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [fullName, setFullName] = useState('');

  const [fullNameValid, setFullNameValid] = useState(false);
  const [cardNumberValid, setCardNumberValid] = useState(false);
  const [cvvValid, setCvvValid] = useState(false);
  const [postalCodeValid, setPostalCodeValid] = useState(false);
  const [expireDateValid, setExpireDateValid] = useState(false);

  const [fullNameEmpty, setFullNameEmpty] = useState(true);
  const [cardNumberEmpty, setCardNumberEmpty] = useState(true);
  const [cvvEmpty, setCvvEmpty] = useState(true);
  const [postalCodeEmpty, setPostalCodeEmpty] = useState(true);
  const [expireDateEmpty, setExpireDateEmpty] = useState(true);

  const [error, setError] = useState('');
  const [actionTokenId, setActionTokenId] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(product);
  const [firstFormFocusHandled, setFirstFormFocusHandled] = useState(false);
  const [isPaymentFormInvalid, setIsPaymentFormInvalid] = useState(false);

  const [cardElementValidationError, setCardElementValidationError] =
    useState('');
  const [fullNameValidationError, setFullNameValidationError] = useState('');
  const [postalCodeValidationError, setPostalCodeValidationError] =
    useState('');

  const cardElementsValidation = () => {
    if (!cardNumberValid && !cardNumberEmpty) {
      setCardElementValidationError('Please enter a valid card number');
    } else if (!expireDateValid && !expireDateEmpty) {
      setCardElementValidationError('Please enter a valid expire date');
    } else if (!cvvValid && !cvvEmpty) {
      setCardElementValidationError('Please enter a valid cvv/cvc code');
    } else {
      setCardElementValidationError('');
    }
  };

  const fullNameValidation = () => {
    if (!fullNameValid && !fullNameEmpty) {
      setFullNameValidationError('Please enter Both First Name and Last Name');
    } else {
      setFullNameValidationError('');
    }
  };

  const postalCodeValidation = () => {
    if (!postalCodeValid && !postalCodeEmpty) {
      setPostalCodeValidationError('Please enter a valid zip code');
    } else {
      setPostalCodeValidationError('');
    }
  };

  useEffect(() => {
    const [firstName, lastName] = fullName.split(' ');
    setFirstName(firstName);
    setLastName(lastName);
  }, [fullName]);

  useEffect(() => fullNameValidation(), [fullNameEmpty, fullNameValid]);
  useEffect(() => postalCodeValidation(), [postalCodeValid, postalCodeEmpty]);
  useEffect(
    () => cardElementsValidation(),
    [
      cardNumberValid,
      expireDateValid,
      cvvValid,
      cardNumberEmpty,
      expireDateEmpty,
      cvvEmpty,
    ],
  );

  useEffect(() => {
    const isFormInvalid = isPostalCodeVisible
      ? !(
          fullNameValid &&
          postalCodeValid &&
          cvvValid &&
          expireDateValid &&
          cardNumberValid
        )
      : !(fullNameValid && cvvValid && expireDateValid && cardNumberValid);

    setIsPaymentFormInvalid(isFormInvalid);
  }, [
    fullNameValid,
    postalCodeValid,
    cvvValid,
    expireDateValid,
    cardNumberValid,
  ]);

  const onChangeCardElementsHandler = (event: any) => {
    setCvvEmpty(event?.cvv.empty);
    setCardNumberEmpty(event?.number.empty);
    setExpireDateEmpty(event?.expiry.empty);
    setCvvValid(event?.cvv.valid);
    setCardNumberValid(event?.number.valid);
    setExpireDateValid(event?.expiry.valid);
  };

  const onChangeFullNameHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const fullName = event.target.value;
    setFullName(fullName);
    setFullNameEmpty(fullName.trim().length === 0);
    setFullNameValid(isFullNameValid(fullName));
  };

  const onChangePostalCodeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const postalCode = event.target.value;
    setPostalCodeEmpty(postalCode.trim().length === 0);
    setPostalCodeValid(isFormFieldNotEmpty(postalCode));
  };

  const handleSubmit = withRecaptcha(
    async (recaptchaToken: string) => {
      if (isLoading) return;

      setLoading(true);
      setError('');
      appAnalytics.trackEvent(new Subscribe());

      const form = formRef.current;
      if (!form) return;

      recurly.token(form, (err, token) => {
        if (err != null) {
          setLoading(false);
          setError(err.message);
        } else {
          dispatch(setSubscriptionToken(token.id));
          subscribe(token.id, product, recaptchaToken);
        }
      });
    },
    setLoading,
    setError,
  );

  const subscribe = (
    token: string,
    currentProduct: Product,
    recaptchaToken: string,
  ) => {
    const zip = postalCodeRef.current?.value;

    const createSubscriptionPromise = createSubscription({
      product: currentProduct,
      token,
      currency,
      paymentMethod,
      recaptchaToken,
    });
    createSubscriptionPromise
      .then(async (result) => {
        if (!!result.three_d_secure_action_token_id) {
          setActionTokenId(result.three_d_secure_action_token_id);
        } else {
          if (isWorkbookUpsellFlow()) {
            dispatch(pushWithSearchQuery('/workbook'));
          } else {
            dispatch(setTrialOfferShown(true));
          }
          setLoading(false);
          onSuccess({
            orderId: result.purchase.order_id,
            productBought: currentProduct,
            zip,
            firstName,
            lastName,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        setError(error.debug_text);
        onError(error.debug_text);
      });
    setSelectedProduct(currentProduct);
  };

  const handleThreeDSecureError = (error: string) => {
    setActionTokenId('');
    setError(error);
    onError(error);
  };

  const handleThreeDSecureToken = withRecaptcha(
    // @ts-ignore
    async (recaptchaToken: string, threeDSToken: string) => {
      setActionTokenId('');
      setLoading(true);

      const createSubscriptionPromise = createSubscription({
        product: selectedProduct,
        token: subscriptionToken,
        currency,
        threeDSToken,
        paymentMethod,
        recaptchaToken,
      });
      const zip = postalCodeRef.current?.value;
      createSubscriptionPromise
        .then(async (result) => {
          setLoading(false);
          onSuccess({
            orderId: result.purchase.order_id,
            productBought: selectedProduct,
            zip,
            firstName,
            lastName,
          });
        })
        .catch((error) => {
          setLoading(false);
          if (!!error.actionTokenId) {
            setActionTokenId(error.actionTokenId);
          } else {
            setError(error.debug_text);
            onError(error);
          }
        });
    },
    setLoading,
    setError,
  );

  const handleFormInputFocus = () => {
    if (!firstFormFocusHandled) {
      appAnalytics.trackEvent(new CardFormInputStarted());
      setFirstFormFocusHandled(true);
    }
  };

  return {
    error,
    postalCodeRef,
    formRef,
    actionTokenId,
    fullName,
    firstName,
    lastName,
    cardElementValidationError,
    fullNameValidationError,
    postalCodeValidationError,
    isPaymentFormInvalid,
    isLoading,
    isPostalCodeVisible,
    handleThreeDSecureError,
    setError,
    handleSubmit,
    handleFormInputFocus,
    handleThreeDSecureToken,
    onChangeCardElementsHandler,
    onChangeFullNameHandler,
    onChangePostalCodeHandler,
  };
};
