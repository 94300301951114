import { QuestionIds } from '../constants/QuestionIds';
import {
  isDefaultOnboarding,
  isEducationV1Quiz,
  isEducationV2Quiz,
  isEducationV3Quiz,
  isGadTest,
  isInsomniaV1Quiz,
  isInsomniaV2Quiz,
} from 'navigation';
import { Steps } from '../constants/StepsLabels';
import { DefaultOnboardingSteps } from './DefaultOnboardingSteps';
import { GadTestSteps } from './GadSteps';
import { BasicSteps } from './BasicSteps';
import { EducationV1TestSteps } from './EducationQuizV1Steps';
import { EducationV2TestSteps } from './EducationQuizV2Steps';
import { EducationV3TestSteps } from './EducationQuizV3Steps';
import { InsomniaQuizV1Steps } from './InsomniaQuizV1Steps';
import { InsomniaQuizV2Steps } from './InsomniaQuizV2Steps';

const isDefaultOnboardingFlow = isDefaultOnboarding();
const isGadFlow = isGadTest();
const isEducationV1Flow = isEducationV1Quiz();
const isEducationV2Flow = isEducationV2Quiz();
const isEducationV3Flow = isEducationV3Quiz();
const isInsomniaV1QuizFlow = isInsomniaV1Quiz();
const isInsomniaV2QuizFlow = isInsomniaV2Quiz();

const getSteps = (): Map<Steps, QuestionIds[]> => {
  switch (true) {
    case isDefaultOnboardingFlow:
      return DefaultOnboardingSteps;
    case isGadFlow:
      return GadTestSteps;
    case isEducationV1Flow:
      return EducationV1TestSteps;
    case isEducationV2Flow:
      return EducationV2TestSteps;
    case isEducationV3Flow:
      return EducationV3TestSteps;
    case isInsomniaV1QuizFlow:
      return InsomniaQuizV1Steps;
    case isInsomniaV2QuizFlow:
      return InsomniaQuizV2Steps;
    default:
      return BasicSteps;
  }
};

export const StepsData: Map<Steps, QuestionIds[]> = getSteps();
