import { isDefaultOnboarding, isGadTest } from 'navigation';
import { BlitzQuestion } from 'onboarding/question/analysisloader/BlitzQuestion';
import { ColorOption } from 'onboarding/question/colors/ColorOption';
import { ColorQuestion } from 'onboarding/question/colors/ColorQuestion';
import { ImageQuestion } from 'onboarding/question/image/ImageQuestion';
import { Info } from 'onboarding/question/info/Info';
import {
  OptionalQuestion,
  OptionQuestion,
} from 'onboarding/question/options/OptionQuestion';
import { TextOption } from 'onboarding/question/options/TextOption';
import { PlaceQuestion } from 'onboarding/question/places/PlaceQuestion';
import { PlaceOption } from 'onboarding/question/places/PlaceOption';
import { ReactComponent as Cafe } from 'assets/places/cafe.svg';
import { ReactComponent as Castle } from 'assets/places/castle.svg';
import { ReactComponent as Fireplace } from 'assets/places/fireplace.svg';
import { ReactComponent as Forest } from 'assets/places/forest.svg';
import { ReactComponent as Harbour } from 'assets/places/harbour.svg';
import { ReactComponent as Kingdom } from 'assets/places/kingdom.svg';
import { ReactComponent as Sakura } from 'assets/places/sakura.svg';
import { ReactComponent as Sanctuary } from 'assets/places/sanctuary.svg';
import { CustomQuestion, Question } from 'onboarding/question/Question';
import { StatementQuestion } from 'onboarding/question/statement/StatementQuestion';
import { QuestionIds } from './constants/QuestionIds';
import { getLocaleIntervalTimeString } from 'utils/utils';
import { EducationAnswer } from 'onboarding/question/eduanswer/EducationAnswer';

export const QUESTIONS_META: Record<QuestionIds, Question> = {
  [QuestionIds.Welcome]: new CustomQuestion(QuestionIds.Welcome, false),
  [QuestionIds.RMeqQ1]: new OptionQuestion(
    QuestionIds.RMeqQ1,
    QuestionIds.RMeqQ1,
    [
      new TextOption('5_6:30am', getLocaleIntervalTimeString('5:00-6:30')),
      new TextOption('6:30_7:45am', getLocaleIntervalTimeString('6:30-7:45')),
      new TextOption('7:45_9:45am', getLocaleIntervalTimeString('7:45-9:45')),
      new TextOption('9:45_11am', getLocaleIntervalTimeString('9:45-11:00')),
      new TextOption('11_12pm', getLocaleIntervalTimeString('11:00-12:00')),
    ],
  ),
  [QuestionIds.RMeqQ2]: new OptionQuestion(
    QuestionIds.RMeqQ2,
    QuestionIds.RMeqQ2,
    [
      new TextOption('very_tired', 'very_tired'),
      new TextOption('fairly_tired', 'fairly_tired'),
      new TextOption('fairly_refreshed', 'fairly_refreshed'),
      new TextOption('very_refreshed', 'very_refreshed'),
    ],
  ),
  [QuestionIds.RMeqQ3]: new OptionQuestion(
    QuestionIds.RMeqQ3,
    QuestionIds.RMeqQ3,
    [
      new TextOption('8_9pm', getLocaleIntervalTimeString('20:00-21:00')),
      new TextOption('9_10:15pm', getLocaleIntervalTimeString('21:00-22:15')),
      new TextOption(
        '10:15_12:45am',
        getLocaleIntervalTimeString('22:15-00:45'),
      ),
      new TextOption('12:45_2am', getLocaleIntervalTimeString('00:45-2:00')),
      new TextOption('2_3am', getLocaleIntervalTimeString('2:00-3:00')),
    ],
  ),
  [QuestionIds.RMeqQ4]: new OptionQuestion(
    QuestionIds.RMeqQ4,
    QuestionIds.RMeqQ4,
    [
      new TextOption('5_8am', getLocaleIntervalTimeString('5:00-8:00')),
      new TextOption('8_10am', getLocaleIntervalTimeString('8:00-10:00')),
      new TextOption('10am_5pm', getLocaleIntervalTimeString('10:00-17:00')),
      new TextOption('5_10pm', getLocaleIntervalTimeString('17:00-22:00')),
      new TextOption('10pm_5am', getLocaleIntervalTimeString('22:00-5:00')),
    ],
  ),
  [QuestionIds.RMeqQ5]: new OptionQuestion(
    QuestionIds.RMeqQ5,
    QuestionIds.RMeqQ5,
    [
      new TextOption('definitely_morning', 'definitely_morning'),
      new TextOption('rather_morning', 'rather_morning'),
      new TextOption('rather_evening', 'rather_evening'),
      new TextOption('definitely_evening', 'definitely_evening'),
    ],
  ),
  [QuestionIds.EduD1]: new Info(
    QuestionIds.EduD1,
    'Almost there! \n Now let’s customize your plan',
    'In the next section we want to understand what you already know about how your internal clock works so that we can better personalize your course',
  ),
  [QuestionIds.EduQ1]: new OptionQuestion(
    QuestionIds.EduQ1,
    "What is a person's chronotype?",
    [
      new TextOption('age', 'Their age'),
      new TextOption('sleep_preference', 'Natural sleep preference'),
      new TextOption('height', 'Their height'),
      new TextOption('occupation', 'Their occupation'),
    ],
  ),
  [QuestionIds.EduQ1Answer]: new EducationAnswer(
    QuestionIds.EduQ1Answer,
    'sleep_preference',
    'Natural sleep preference',
    "A person's chronotype is their natural sleep preference, or the time of day when they are most alert and productive.",
  ),
  [QuestionIds.EduQ2]: new OptionQuestion(
    QuestionIds.EduQ2,
    'Can a person change their chronotype?',
    [
      new TextOption('age', "No, a person's chronotype is fixed"),
      new TextOption('effort', 'Yes, but it requires a lot of effort'),
      new TextOption(
        'medical procedure',
        'Only if they undergo a medical procedure',
      ),
    ],
  ),
  [QuestionIds.EduQ2Answer]: new EducationAnswer(
    QuestionIds.EduQ2Answer,
    'effort',
    'Yes, but it requires a lot of effort',
    "While a person's chronotype is largely determined by genetics, it is possible to shift it to some extent through consistent changes to sleep and wake times and other lifestyle habits.",
  ),
  [QuestionIds.EduQ3]: new OptionQuestion(
    QuestionIds.EduQ3,
    'What is a circadian rhythm?',
    [
      new TextOption('personal_preference', 'Just a personal preference'),
      new TextOption('biological_clock', 'The 24-hour biological clock'),
      new TextOption(
        'total_sleep_amount',
        'The total amount of sleep a person needs per night',
      ),
      new TextOption(
        'amount_time',
        'The amount of time it takes a person to fall asleep',
      ),
    ],
  ),
  [QuestionIds.EduQ3Answer]: new EducationAnswer(
    QuestionIds.EduQ3Answer,
    'biological_clock',
    'The 24-hour biological clock',
    'A circadian rhythm is the 24-hour biological clock that regulates sleep-wake cycles, influencing the timing and quality of sleep.',
  ),
  [QuestionIds.EduQ4]: new OptionQuestion(
    QuestionIds.EduQ4,
    'What’s a circadian rhythm disorder?',
    [
      new TextOption('sleep_wake_cycle', 'Sleep-wake cycle disorder'),
      new TextOption('medicine_type', 'Type of medicine'),
    ],
  ),
  [QuestionIds.EduQ4Answer]: new EducationAnswer(
    QuestionIds.EduQ4Answer,
    'sleep_wake_cycle',
    'Sleep-wake cycle disorder',
    'Circadian rhythm disorders, also known as sleep-wake cycle disorders, are problems that occur when your body’s internal clock, which tells you when it’s time to sleep or wake, is out of sync with your environment.',
  ),
  [QuestionIds.EduQ5]: new OptionQuestion(
    QuestionIds.EduQ5,
    'What is a common symptom of circadian rhythm disorders?',
    [
      new TextOption('night_wake_up', 'Waking up at night'),
      new TextOption('daytime_sleepiness', 'Daytime sleepiness'),
      new TextOption('sleepwalking', 'Sleepwalking'),
      new TextOption('nightmares', 'Nightmares'),
    ],
  ),
  [QuestionIds.EduQ5Answer]: new EducationAnswer(
    QuestionIds.EduQ5Answer,
    'daytime_sleepiness',
    'Daytime sleepiness',
    'A common symptom of circadian rhythm disorders is daytime sleepiness, or excessive sleepiness during the day that interferes with daily activities.',
  ),
  [QuestionIds.MeqResults]: new CustomQuestion(QuestionIds.MeqResults, false),
  [QuestionIds.GadQ1]: new StatementQuestion(
    QuestionIds.GadQ1,
    'GadTitle',
    'GadQ1Statement',
    true,
  ),
  [QuestionIds.GadQ2]: new StatementQuestion(
    QuestionIds.GadQ2,
    'GadTitle',
    'GadQ2Statement',
    true,
  ),
  [QuestionIds.GadQ3]: new StatementQuestion(
    QuestionIds.GadQ3,
    'GadTitle2',
    'GadQ3Statement',
    true,
  ),
  [QuestionIds.GadQ4]: new StatementQuestion(
    QuestionIds.GadQ4,
    'GadTitle2',
    'GadQ4Statement',
    true,
  ),
  [QuestionIds.GadQ5]: new StatementQuestion(
    QuestionIds.GadQ5,
    'GadTitle2',
    'GadQ5Statement',
    true,
  ),
  [QuestionIds.GadQ6]: new StatementQuestion(
    QuestionIds.GadQ6,
    'GadTitle2',
    'GadQ6Statement',
    true,
  ),
  [QuestionIds.GadQ7]: new StatementQuestion(
    QuestionIds.GadQ7,
    'GadTitle2',
    'GadQ7Statement',
    true,
  ),
  [QuestionIds.GadResults]: new CustomQuestion(QuestionIds.GadResults, false),
  [QuestionIds.IsiQ1]: new OptionQuestion(
    QuestionIds.IsiQ1,
    'How SATISFIED/DISSATISFIED are you with your CURRENT sleep pattern?',
    [
      new TextOption('very_satisfied', 'Very Satisfied'),
      new TextOption('satisfied', 'Satisfied'),
      new TextOption('moderately_satisfied', 'Moderately Satisfied'),
      new TextOption('dissatisfied', 'Dissatisfied'),
      new TextOption('very_dissatisfied', 'Very Dissatisfied'),
    ],
  ),
  [QuestionIds.IsiQ2]: new OptionQuestion(
    QuestionIds.IsiQ2,
    'How NOTICEABLE to others do you think your sleep problem is in terms of impairing the quality of your life?',
    [
      new TextOption('not_at_all', 'Not at all Noticeable'),
      new TextOption('little', 'A Little'),
      new TextOption('somewhat', 'Somewhat'),
      new TextOption('much', 'Much'),
      new TextOption('very_much', 'Very Much Noticeable'),
    ],
  ),
  [QuestionIds.IsiQ3]: new OptionQuestion(
    QuestionIds.IsiQ3,
    'How WORRIED/DISTRESSED are you about your current sleep problem?',
    [
      new TextOption('not_at_all', 'Not at all Worried'),
      new TextOption('little', 'A Little'),
      new TextOption('somewhat', 'Somewhat'),
      new TextOption('much', 'Much'),
      new TextOption('very_much', 'Very Much Worried'),
    ],
  ),
  [QuestionIds.IsiQ4]: new OptionQuestion(
    QuestionIds.IsiQ4,
    'To what extent do you consider your sleep problem to INTERFERE with your daily functioning?',
    [
      new TextOption('not_at_all', 'Not at all Interfering'),
      new TextOption('little', 'A Little'),
      new TextOption('somewhat', 'Somewhat'),
      new TextOption('much', 'Much'),
      new TextOption('very_much', 'Very Much Interfering'),
    ],
  ),
  [QuestionIds.IsiQ5]: new OptionQuestion(
    QuestionIds.IsiQ5,
    'Which of the following symptoms you experience during the day?',
    [
      new TextOption('mood_swings', 'Mood swings'),
      new TextOption('daytime_fatigue', 'Daytime fatigue'),
      new TextOption('inability_to_focus', 'Inability to Focus'),
      new TextOption('headaches', 'Headaches'),
      new TextOption('irritability_or_anxiety', 'Irritability or anxiety'),
      new TextOption('worries_about_sleep', 'Worries about sleep'),
    ],
    true,
  ),
  [QuestionIds.IsiD1]: new CustomQuestion(QuestionIds.IsiD1),
  [QuestionIds.AboutYouGuided]: new OptionQuestion(
    QuestionIds.AboutYouGuided,
    QuestionIds.AboutYouGuided,
    [
      new TextOption('new_to_it', 'new_to_it'),
      new TextOption('not_much', 'not_much'),
      new TextOption('expert', 'expert'),
    ],
  ),
  [QuestionIds.AboutYouD1]: new OptionalQuestion(
    QuestionIds.AboutYouGuided,
    QuestionIds.AboutYouD1,
    {
      new_to_it: new Info(
        QuestionIds.AboutYouD1,
        'AboutYouD1Title',
        'AboutYouD1NewToItDescription',
      ),
      not_much: new Info(
        QuestionIds.AboutYouD1,
        'AboutYouD1Title',
        'AboutYouD1NotMuchDescription',
      ),
      expert: new Info(
        QuestionIds.AboutYouD1,
        'AboutYouD1Title',
        'AboutYouD1ExpertDescription',
      ),
    },
  ),
  [QuestionIds.AboutYouGender]: new OptionQuestion(
    QuestionIds.AboutYouGender,
    QuestionIds.AboutYouGender,
    [
      new TextOption('female', 'female'),
      new TextOption('male', 'male'),
      new TextOption('non_binary', 'non_binary'),
      new TextOption('none', 'none'),
    ],
  ),
  [QuestionIds.AboutYouAge]: new OptionQuestion(
    QuestionIds.AboutYouAge,
    QuestionIds.AboutYouAge,
    [
      new TextOption('20s', '20s'),
      new TextOption('30s', '30s'),
      new TextOption('40s', '40s'),
      new TextOption('50s', '50s'),
      new TextOption('60s', '60s'),
    ],
  ),
  [QuestionIds.AboutYouRoutine]: new OptionQuestion(
    QuestionIds.AboutYouRoutine,
    isGadTest() ? 'AboutYourRotineMentalHealth' : 'AboutYourRotineSleep',
    [
      new TextOption('no_routine', 'no_routine'),
      new TextOption('some_routine', 'some_routine'),
      new TextOption('mindful_routine', 'mindful_routine'),
    ],
  ),
  [QuestionIds.AboutYouD2]: new OptionalQuestion(
    QuestionIds.AboutYouRoutine,
    QuestionIds.AboutYouD2,
    {
      no_routine: new Info(
        QuestionIds.AboutYouD2,
        'AboutYouD2NoRotineTitle',
        'AboutYouD2NoRotineDescription',
      ),
      some_routine: new Info(
        QuestionIds.AboutYouD2,
        'AboutYouD2SomeRotineTitle',
        'AboutYouD2SomeRotineDescription',
      ),
      mindful_routine: new Info(
        QuestionIds.AboutYouD2,
        'AboutYouD2MindfulRotineTitle',
        'AboutYouD2MindfulRotineDescription',
      ),
    },
  ),
  [QuestionIds.AboutYouKids]: new OptionQuestion(
    QuestionIds.AboutYouKids,
    QuestionIds.AboutYouKids,
    [
      new TextOption('under_16', 'under_16'),
      new TextOption('grown_ups', 'grown_ups'),
      new TextOption('no_kids', 'no_kids'),
    ],
  ),
  [QuestionIds.AboutYouD3]: new OptionalQuestion(
    QuestionIds.AboutYouKids,
    QuestionIds.AboutYouD3,
    {
      under_16: new Info(
        QuestionIds.AboutYouD3,
        'AboutYouD3Title',
        'AboutYouD3Description',
      ),
    },
  ),
  [QuestionIds.AboutYouActivity]: new OptionQuestion(
    QuestionIds.AboutYouActivity,
    QuestionIds.AboutYouActivity,
    [
      new TextOption('low', 'low'),
      new TextOption('average', 'average'),
      new TextOption('high', 'high'),
    ],
  ),
  [QuestionIds.AboutYouD4]: isGadTest()
    ? new Info(
        QuestionIds.AboutYouD4,
        'AboutYouD4Title',
        'AboutYouD4Description',
      )
    : new Info(
        QuestionIds.AboutYouD4,
        'AboutYouD4V2Title',
        'AboutYouD4V2Description',
      ),
  [QuestionIds.AboutYouProof1]: new CustomQuestion(
    QuestionIds.AboutYouProof1,
    true,
  ),
  [QuestionIds.SleepAndActivityQ1]: new OptionalQuestion(
    QuestionIds.Welcome,
    QuestionIds.SleepAndActivityQ1,
    {
      sleep: new OptionQuestion(
        QuestionIds.SleepAndActivityQ1,
        'SleepAndActivityQ1Sleep',
        [
          new TextOption('occasionally', 'occasionally'),
          new TextOption('frequently', 'frequently'),
          new TextOption('every_night', 'every_night'),
        ],
      ),
      stress: new OptionQuestion(
        QuestionIds.SleepAndActivityQ1,
        'SleepAndActivityQ1Stress',
        [
          new TextOption('good', 'good'),
          new TextOption('stressed', 'stressed'),
          new TextOption('sad', 'sad'),
          new TextOption('indifferent', 'indifferent'),
          new TextOption('mentally_tired', 'mentally_tired'),
        ],
      ),
    },
  ),
  [QuestionIds.SleepAndActivityQ2]: new OptionalQuestion(
    QuestionIds.Welcome,
    QuestionIds.SleepAndActivityQ2,
    {
      sleep: new OptionQuestion(
        QuestionIds.SleepAndActivityQ2,
        'SleepAndActivityQ2Sleep',
        [
          new TextOption('relationship', 'relationship'),
          new TextOption('future', 'future'),
          new TextOption('job', 'job'),
          new TextOption('family', 'family'),
          new TextOption('health', 'health_issues'),
          new TextOption('stress', 'stress'),
        ],
        true,
      ),
      stress: new OptionQuestion(
        QuestionIds.SleepAndActivityQ2,
        'SleepAndActivityQ2Stress',
        [
          new TextOption('relationship', 'relationship'),
          new TextOption('future', 'future'),
          new TextOption('job', 'job'),
          new TextOption('family', 'family'),
          new TextOption('health', 'health_issues'),
          new TextOption('stress', 'stress'),
        ],
        true,
      ),
    },
  ),
  [QuestionIds.SleepAndActivityD1]: isDefaultOnboarding()
    ? new OptionalQuestion(
        QuestionIds.Welcome,
        QuestionIds.SleepAndActivityD1,
        {
          sleep: new Info(
            QuestionIds.SleepAndActivityD1,
            'SleepAndActivityD1SleepTitle',
            'SleepAndActivityD1SleepAndStressDescription',
          ),
          stress: new Info(
            QuestionIds.SleepAndActivityD1,
            'SleepAndActivityD1StressTitle',
            'SleepAndActivityD1SleepAndStressDescription',
          ),
        },
      )
    : new Info(
        QuestionIds.SleepAndActivityD1,
        'SleepAndActivityD1Title',
        `${
          isGadTest()
            ? `SleepAndActivityD1DescriptionGad`
            : `SleepAndActivityD1Description`
        }`,
      ),
  [QuestionIds.SleepAndActivityQ3]: new OptionalQuestion(
    QuestionIds.Welcome,
    QuestionIds.SleepAndActivityQ3,
    {
      sleep: new OptionQuestion(
        QuestionIds.SleepAndActivityQ3,
        QuestionIds.SleepAndActivityQ3,
        [
          new TextOption('insomnia', 'insomnia'),
          new TextOption('restless_sleep', 'restless_sleep'),
          new TextOption('awake_at_night', 'awake_at_nightt'),
          new TextOption('bad_morning_mood', 'bad_morning_mood'),
          new TextOption('other', 'other', true),
        ],
        true,
      ),
      stress: new OptionQuestion(
        QuestionIds.SleepAndActivityQ3,
        QuestionIds.SleepAndActivityQ3,
        [
          new TextOption('anxious_thoughts', 'anxious_thoughts'),
          new TextOption('discomfort', 'discomfort'),
          new TextOption('moodiness', 'moodiness'),
          new TextOption('sleep_issues', 'sleep_issues'),
          new TextOption('other', 'other', true),
        ],
        true,
      ),
    },
  ),
  [QuestionIds.SleepAndActivityProof1]: new CustomQuestion(
    QuestionIds.SleepAndActivityProof1,
    false,
  ),
  [QuestionIds.SleepAndActivityProof2]: new CustomQuestion(
    QuestionIds.SleepAndActivityProof2,
  ),
  [QuestionIds.SleepAndActivityCoffee]: new StatementQuestion(
    QuestionIds.SleepAndActivityCoffee,
    'SleepAndActivityTitle',
    'SleepAndActivityCoffeeStatement',
  ),
  [QuestionIds.SleepAndActivityDinner]: new StatementQuestion(
    QuestionIds.SleepAndActivityDinner,
    'SleepAndActivityTitle',
    'SleepAndActivityDinnerStatement',
  ),
  [QuestionIds.SleepAndActivityAfterMidnight]: new StatementQuestion(
    QuestionIds.SleepAndActivityAfterMidnight,
    'SleepAndActivityTitle',
    'SleepAndActivityAfterMidnightStatement',
  ),
  [QuestionIds.SleepAndActivityLessThanEightHours]: new StatementQuestion(
    QuestionIds.SleepAndActivityLessThanEightHours,
    'SleepAndActivityTitle',
    'SleepAndActivityLessThanEightHoursStatement',
  ),
  [QuestionIds.SleepAndActivityExtraMotivation]: new StatementQuestion(
    QuestionIds.SleepAndActivityExtraMotivation,
    'SleepAndActivityTitle',
    'SleepAndActivityExtraMotivationStatement',
  ),
  [QuestionIds.SleepAndActivityD2]: new OptionalQuestion(
    QuestionIds.SleepAndActivityExtraMotivation,
    QuestionIds.SleepAndActivityD2,
    {
      yes: new Info(
        QuestionIds.SleepAndActivityD2,
        'SleepAndActivityD2YesTitle',
        'SleepAndActivityD2YesDescription',
      ),
      no: new Info(
        QuestionIds.SleepAndActivityD2,
        'SleepAndActivityD2NoTitle',
        'SleepAndActivityD2NoDescription',
      ),
    },
  ),
  [QuestionIds.SleepAndActivityLoader]: new CustomQuestion(
    QuestionIds.SleepAndActivityLoader,
  ),
  [QuestionIds.PersonalizationD1]: new CustomQuestion(
    QuestionIds.PersonalizationD1,
  ),
  [QuestionIds.PersonalizationColors1]: new ColorQuestion(
    QuestionIds.PersonalizationColors1,
    'PersonalizationColorsTitle',
    'PersonalizationColorsDescription',
    [
      new ColorOption('sky_blue', '#7986CB', 'sky_blue'),
      new ColorOption('loona_lavender', '#7E57C2', 'loona_lavender'),
    ],
  ),
  [QuestionIds.PersonalizationColors2]: new ColorQuestion(
    QuestionIds.PersonalizationColors2,
    'PersonalizationColorsTitle',
    'PersonalizationColorsDescription',
    [
      new ColorOption('venice_rose', '#F088AB', 'venice_rose'),
      new ColorOption('coral_red', '#EF5350', 'coral_red'),
    ],
  ),
  [QuestionIds.PersonalizationColors3]: new ColorQuestion(
    QuestionIds.PersonalizationColors3,
    'PersonalizationColorsTitle',
    'PersonalizationColorsDescription',
    [
      new ColorOption('navy_blue', '#49599A', 'navy_blue'),
      new ColorOption('spring_green', '#64BB6A', 'spring_green'),
    ],
  ),
  [QuestionIds.PersonalizationColors4]: new ColorQuestion(
    QuestionIds.PersonalizationColors4,
    'PersonalizationColorsTitle',
    'PersonalizationColorsDescription',
    [
      new ColorOption('tangerine', '#EC8847', 'tangerine'),
      new ColorOption('sunflower_yellow', '#FFD863', 'sunflower_yellow'),
    ],
  ),
  [QuestionIds.PersonalizationPlaces]: new PlaceQuestion(
    QuestionIds.PersonalizationPlaces,
    'PersonalizationPlacesTitle',
    'PersonalizationPlacesDescription',
    [
      new PlaceOption('sakura_garden', Sakura, 'sakura_garden'),
      new PlaceOption('fairy_tale_castle', Castle, 'fairy_tale_castle'),
      new PlaceOption('hidden_sanctuary', Sanctuary, 'hidden_sanctuary'),
      new PlaceOption('enchanted_forest', Forest, 'enchanted_forest'),
      new PlaceOption('moonlit_harbor', Harbour, 'moonlit_harbor'),
      new PlaceOption('cafe', Cafe, 'cafe'),
      new PlaceOption('kingdom', Kingdom, 'kingdom'),
      new PlaceOption('fireplace', Fireplace, 'fireplace'),
    ],
  ),
  [QuestionIds.PersonalizationProof1]: new CustomQuestion(
    QuestionIds.PersonalizationProof1,
    true,
  ),
  [QuestionIds.PersonalizationArtwork1]: new ImageQuestion(
    QuestionIds.PersonalizationArtwork1,
    'PersonalizationArtworkTitle',
    'PersonalizationArtworkDescription',
    'japan_garden',
  ),
  [QuestionIds.PersonalizationArtwork2]: new ImageQuestion(
    QuestionIds.PersonalizationArtwork2,
    'PersonalizationArtworkTitle',
    'PersonalizationArtworkDescription',
    'magic_tree',
  ),
  [QuestionIds.PersonalizationArtwork3]: new ImageQuestion(
    QuestionIds.PersonalizationArtwork3,
    'PersonalizationArtworkTitle',
    'PersonalizationArtworkDescription',
    'campfire',
  ),
  [QuestionIds.PersonalizationArtwork4]: new ImageQuestion(
    QuestionIds.PersonalizationArtwork4,
    'PersonalizationArtworkTitle',
    'PersonalizationArtworkDescription',
    'brooklyn',
  ),
  [QuestionIds.PersonalizationNarrative]: new OptionQuestion(
    QuestionIds.PersonalizationNarrative,
    QuestionIds.PersonalizationNarrative,
    [
      new TextOption('realistic', 'realistic'),
      new TextOption('mysterious', 'mysterious'),
      new TextOption('fairytale', 'fairytale'),
      new TextOption('nostalgic', 'nostalgic'),
      new TextOption('sentimental', 'sentimental'),
      new TextOption('adventure', 'adventure'),
    ],
    true,
    true,
    'PersonalizationSubtitle',
  ),
  [QuestionIds.PersonalizationD3]: new CustomQuestion(
    QuestionIds.PersonalizationD3,
  ),
  [QuestionIds.PersonalizationLoader]: new CustomQuestion(
    QuestionIds.PersonalizationLoader,
  ),
  [QuestionIds.IntroSignInLoader]: new CustomQuestion(
    QuestionIds.IntroSignInLoader,
  ),
  [QuestionIds.PersonalizationD2]: new Info(
    QuestionIds.PersonalizationD2,
    'PersonalizationD2Title',
    'PersonalizationD2Description',
    'PersonalizationD2Description2',
  ),
  [QuestionIds.PersonalizationGoal]: new OptionalQuestion(
    QuestionIds.Welcome,
    QuestionIds.PersonalizationGoal,
    {
      sleep: new OptionQuestion(
        QuestionIds.PersonalizationGoal,
        'PersonalizationGoalSleep',
        [
          new TextOption('sleep', 'sleep'),
          new TextOption('productivity', 'productivity'),
          new TextOption('wake_up_time', 'wake_up_time'),
          new TextOption('health', 'health'),
          new TextOption('other', 'other', true),
        ],
        false,
        false,
      ),
      stress: new OptionQuestion(
        QuestionIds.PersonalizationGoal,
        'PersonalizationGoalStress',
        [
          new TextOption('sleep', 'sleep'),
          new TextOption('productivity', 'productivity'),
          new TextOption('wake_up_time', 'wake_up_time'),
          new TextOption('health', 'health'),
          new TextOption('other', 'other', true),
        ],
        false,
        false,
      ),
    },
    false,
  ),
  [QuestionIds.BlitzArt]: new BlitzQuestion(
    QuestionIds.BlitzArt,
    QuestionIds.BlitzArt,
  ),
  [QuestionIds.BlitzDaydream]: new BlitzQuestion(
    QuestionIds.BlitzDaydream,
    QuestionIds.BlitzDaydream,
  ),
  [QuestionIds.BlitzShiftWork]: new BlitzQuestion(
    QuestionIds.BlitzShiftWork,
    QuestionIds.BlitzShiftWork,
  ),
  [QuestionIds.BlitzTinnitus]: new BlitzQuestion(
    QuestionIds.BlitzTinnitus,
    QuestionIds.BlitzTinnitus,
  ),
  [QuestionIds.BlitzRead]: new BlitzQuestion(
    QuestionIds.BlitzRead,
    QuestionIds.BlitzRead,
  ),
  [QuestionIds.BlitzADHD]: new BlitzQuestion(
    QuestionIds.BlitzADHD,
    QuestionIds.BlitzADHD,
  ),
  [QuestionIds.PersonalizationLength]: new OptionQuestion(
    QuestionIds.PersonalizationLength,
    'PersonalizationLengthTitle',
    [
      new TextOption('short', 'short'),
      new TextOption('medium', 'medium'),
      new TextOption('long', 'long'),
    ],
    true,
    true,
    'PersonalizationLengthDescription',
  ),
  [QuestionIds.PersonalizationFrequency]: new OptionQuestion(
    QuestionIds.PersonalizationFrequency,
    'PersonalizationFrequencyTitle',
    [
      new TextOption('every_day', 'every_day'),
      new TextOption('wekdays', 'wekdays'),
      new TextOption('2_3_time_week', '2_3_time_week'),
      new TextOption('once_a_week', 'once_a_week'),
    ],
    true,
    true,
    'PersonalizationFrequencyDescription',
  ),
  [QuestionIds.PersonalizationFeelingHoping]: new OptionQuestion(
    QuestionIds.PersonalizationFeelingHoping,
    'PersonalizationFeelingHopingTitle',
    [
      new TextOption('peace', 'peace'),
      new TextOption('happiness', 'happiness'),
      new TextOption('gratitude', 'gratitude'),
      new TextOption('empowerment', 'empowerment'),
      new TextOption('calmness', 'calmness'),
      new TextOption('admiration', 'admiration'),
    ],
    true,
    true,
    'PersonalizationFeelingHopingDescription',
  ),
  [QuestionIds.PersonalizationSounds]: new OptionQuestion(
    QuestionIds.PersonalizationSounds,
    'PersonalizationSoundsTitle',
    [
      new TextOption('nature', 'nature', false, 'nature_subtext'),
      new TextOption('water', 'water', false, 'water_subtext'),
      new TextOption('wildlife', 'wildlife', false, 'wildlife_subtext'),
      new TextOption('urban', 'urban', false, 'urban_subtext'),
      new TextOption('asmr', 'asmr', false, 'asmr_subtext'),
    ],
    true,
    true,
    'PersonalizationSoundsDescription',
  ),
  [QuestionIds.PersonalizationFeeling]: new OptionQuestion(
    QuestionIds.PersonalizationFeeling,
    'PersonalizationFeelingTitle',
    [
      new TextOption('sad', 'sad'),
      new TextOption('happy', 'happy'),
      new TextOption('anxious', 'anxious'),
      new TextOption('cant_sleep', 'cant_sleep'),
      new TextOption('stressed', 'stressed'),
      new TextOption('cant_focus', 'cant_focus'),
    ],
    true,
    true,
    'PersonalizationFeelingDescription',
  ),
};
