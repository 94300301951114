import { Product } from 'bootstrap/data/product/Product';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TrialExplainer } from '../trialexplainer/TrialExplainer';
import styles from './styles.module.scss';

type Props = {
  product?: Product;
};

export const ManualInfoTemplate = ({ product }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.manual}>
      <div className={styles.title}>
        <h3>{t('paymentPageManualTemplateTitle')}</h3>
      </div>
      <TrialExplainer product={product} />
    </div>
  );
};
