import { Reducer } from 'redux';
import { QuestionIds } from 'onboarding/data/constants/QuestionIds';
import { OnboardingActionTypes } from './actions/types';
import { SET_ANSWER, SET_CURRENT_QUESTION } from './actions/answer';
import { OnboardingState } from './state';
import { QuestionsOrder } from 'onboarding/data/QuestionsOrder';

const INITIAL_STATE: OnboardingState = {
  answers: {
    [QuestionIds.Welcome]: undefined,
    [QuestionIds.AboutYouGuided]: undefined,
    [QuestionIds.AboutYouD1]: undefined,
    [QuestionIds.AboutYouGender]: undefined,
    [QuestionIds.AboutYouAge]: undefined,
    [QuestionIds.AboutYouRoutine]: undefined,
    [QuestionIds.AboutYouD2]: undefined,
    [QuestionIds.AboutYouKids]: undefined,
    [QuestionIds.AboutYouD3]: undefined,
    [QuestionIds.AboutYouActivity]: undefined,
    [QuestionIds.AboutYouD4]: undefined,
    [QuestionIds.AboutYouProof1]: undefined,
    [QuestionIds.RMeqQ1]: undefined,
    [QuestionIds.RMeqQ2]: undefined,
    [QuestionIds.RMeqQ3]: undefined,
    [QuestionIds.RMeqQ4]: undefined,
    [QuestionIds.RMeqQ5]: undefined,
    [QuestionIds.MeqResults]: undefined,
    [QuestionIds.GadQ1]: undefined,
    [QuestionIds.GadQ2]: undefined,
    [QuestionIds.GadQ3]: undefined,
    [QuestionIds.GadQ4]: undefined,
    [QuestionIds.GadQ5]: undefined,
    [QuestionIds.GadQ6]: undefined,
    [QuestionIds.GadQ7]: undefined,
    [QuestionIds.GadResults]: undefined,
    [QuestionIds.EduD1]: undefined,
    [QuestionIds.EduQ1]: undefined,
    [QuestionIds.EduQ1Answer]: undefined,
    [QuestionIds.EduQ2]: undefined,
    [QuestionIds.EduQ2Answer]: undefined,
    [QuestionIds.EduQ3]: undefined,
    [QuestionIds.EduQ3Answer]: undefined,
    [QuestionIds.EduQ4]: undefined,
    [QuestionIds.EduQ4Answer]: undefined,
    [QuestionIds.EduQ5]: undefined,
    [QuestionIds.EduQ5Answer]: undefined,
    [QuestionIds.IsiQ1]: undefined,
    [QuestionIds.IsiQ2]: undefined,
    [QuestionIds.IsiQ3]: undefined,
    [QuestionIds.IsiQ4]: undefined,
    [QuestionIds.IsiQ5]: undefined,
    [QuestionIds.IsiD1]: undefined,
    [QuestionIds.SleepAndActivityQ1]: undefined,
    [QuestionIds.SleepAndActivityQ2]: undefined,
    [QuestionIds.SleepAndActivityD1]: undefined,
    [QuestionIds.SleepAndActivityQ3]: undefined,
    [QuestionIds.SleepAndActivityProof1]: undefined,
    [QuestionIds.SleepAndActivityProof2]: undefined,
    [QuestionIds.SleepAndActivityCoffee]: undefined,
    [QuestionIds.SleepAndActivityDinner]: undefined,
    [QuestionIds.SleepAndActivityAfterMidnight]: undefined,
    [QuestionIds.SleepAndActivityLessThanEightHours]: undefined,
    [QuestionIds.SleepAndActivityExtraMotivation]: undefined,
    [QuestionIds.SleepAndActivityD2]: undefined,
    [QuestionIds.SleepAndActivityLoader]: undefined,
    [QuestionIds.PersonalizationD1]: undefined,
    [QuestionIds.PersonalizationColors1]: undefined,
    [QuestionIds.PersonalizationColors2]: undefined,
    [QuestionIds.PersonalizationColors3]: undefined,
    [QuestionIds.PersonalizationColors4]: undefined,
    [QuestionIds.PersonalizationProof1]: undefined,
    [QuestionIds.PersonalizationArtwork1]: undefined,
    [QuestionIds.PersonalizationArtwork2]: undefined,
    [QuestionIds.PersonalizationArtwork3]: undefined,
    [QuestionIds.PersonalizationArtwork4]: undefined,
    [QuestionIds.PersonalizationNarrative]: undefined,
    [QuestionIds.PersonalizationD3]: undefined,
    [QuestionIds.PersonalizationLoader]: undefined,
    [QuestionIds.PersonalizationLength]: undefined,
    [QuestionIds.PersonalizationFrequency]: undefined,
    [QuestionIds.PersonalizationFeelingHoping]: undefined,
    [QuestionIds.PersonalizationSounds]: undefined,
    [QuestionIds.PersonalizationFeeling]: undefined,
    [QuestionIds.IntroSignInLoader]: undefined,
    [QuestionIds.PersonalizationD2]: undefined,
    [QuestionIds.PersonalizationGoal]: undefined,
    [QuestionIds.PersonalizationPlaces]: undefined,
    [QuestionIds.BlitzArt]: undefined,
    [QuestionIds.BlitzDaydream]: undefined,
    [QuestionIds.BlitzTinnitus]: undefined,
    [QuestionIds.BlitzRead]: undefined,
    [QuestionIds.BlitzShiftWork]: undefined,
    [QuestionIds.BlitzADHD]: undefined,
  },
  currentStep: QuestionsOrder[0],
};

export const onboardingReducer: Reducer<
  OnboardingState,
  OnboardingActionTypes
> = (state = INITIAL_STATE, action: OnboardingActionTypes) => {
  switch (action.type) {
    case SET_ANSWER:
      return {
        ...state,
        answers: {
          ...state.answers,
          [action.payload.question]: action.payload.answer,
        },
      };
    case SET_CURRENT_QUESTION: {
      return {
        ...state,
        currentStep: action.question,
      };
    }

    default:
      return state;
  }
};
