import React from 'react';
import ExplainerItem from '../components/explaineritem/Item';
import { ReactComponent as UnLock } from 'assets/stepper/unlock.svg';
import { ReactComponent as Bell } from 'assets/stepper/bell.svg';
import { ReactComponent as Checked } from 'assets/stepper/check.svg';
import { ReactComponent as Star } from 'assets/stepper/star.svg';
import explainerItemStyles from '../components/explaineritem/styles.module.scss';
import { ExplainerWrapper } from '../components/explainerwrapper/Wrapper';

export const DefaultTrialExplainer = () => {
  return (
    <ExplainerWrapper>
      <ExplainerItem
        className={explainerItemStyles.check}
        icon={<Checked />}
        title="stepperTitleStep1"
        description="stepperDescriptionStep1"
      />
      <ExplainerItem
        className={explainerItemStyles.lock}
        icon={<UnLock />}
        title="stepperTitleStep2"
        description="stepperDescriptionStep2"
      />
      <ExplainerItem
        className={explainerItemStyles.bell}
        icon={<Bell />}
        title="stepperTitleStep3"
        description="stepperDescriptionStep3"
      />
      <ExplainerItem
        className={explainerItemStyles.star}
        icon={<Star />}
        title="stepperTitleStep4"
        description="stepperDescriptionStep4"
      />
    </ExplainerWrapper>
  );
};
