import React from 'react';
import { useTranslation } from 'react-i18next';
import { isPaymentPlanSelectorDirect } from 'navigation';
import { StickyHeader } from 'subscription/components/stickyheader/StickyHeader';
import { Product } from 'bootstrap/data/product/Product';
import styles from './styles.module.scss';

type Props = {
  product: Product;
  handleGetPlanClick: () => void;
  children: JSX.Element[] | JSX.Element;
};

export const PlanSectionWrapper = ({
  product,
  children,
  handleGetPlanClick,
}: Props) => {
  const { t } = useTranslation();

  const startDate = Date.now();

  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>
        {isPaymentPlanSelectorDirect()
          ? 'Choose your plan'
          : 'Choose your plan after trial:'}
      </h3>

      <StickyHeader startDate={startDate} trialLength={product.trialLength} />

      {children}

      <button className={styles.submit} onClick={handleGetPlanClick}>
        {t('Continue')}
      </button>
    </div>
  );
};
