import React from 'react';
import { isDefaultOnboarding, isGadTest } from 'navigation';
import DefaultWelcome from './defaultwelcome/Welcome';
import GadWelcome from './gadwelcome/GadWelcome';
import RmeqWelcome from './rmeqwelcome/WelcomeRmeq';

const Welcome = () => {
  if (isGadTest()) {
    return <GadWelcome />;
  }
  if (isDefaultOnboarding()) {
    return <DefaultWelcome />;
  }
  return <RmeqWelcome />;
};

export default Welcome;
