import React, { useEffect, FC } from 'react';
import { useAppDispatch, useAppSelector } from 'bootstrap/hooks';
import { setPaymentMethod } from 'bootstrap/user/actions/setUserInfo';
import Maestro from 'assets/payments/maestro.svg';
import Amex from 'assets/payments/amex.svg';
import MasterCard from 'assets/payments/mastercard.svg';
import Visa from 'assets/payments/visa.svg';
import PayPalIcon from 'assets/payments/paypal_v2.svg';
import ApplePayIcon from 'assets/payments/icon_apple_pay.svg';
import { Product } from 'bootstrap/data/product/Product';
import { ApplePayElement } from 'subscription/recurly/components/elements/applepay/ApplePayElement';
import { PayPalElement } from 'subscription/recurly/components/elements/paypal/PayPalElement';
import { CardFormWrapper } from 'subscription/recurly/components/cardform/CardFormWrapper';
import { SuccessPaymentProps } from 'subscription/recurly/Recurly.types';
import { usePaymentsLogic } from 'subscription/recurly/components/paymentpicker/PaymentsPicker.hook';
import { RecurlyLogo } from 'subscription/recurly/components/logo/RecurlyLogo';
import { PaymentMethods } from 'subscription/components/Payments/Payments.types';
import { Item } from 'subscription/components/Payments/accordion/item/Item';
import { isApplePayAvailable } from 'subscription/components/Payments/Payments.utlls';
import Alert from 'components/error/Error';
import styles from './styles.module.scss';

interface PaymentMethodsContent {
  title: string;
  icon: string | JSX.Element;
}

interface Props {
  product: Product;
  options: PaymentMethods[];
  children?: JSX.Element | JSX.Element[];
  onSuccess?: (params: SuccessPaymentProps) => void;
  onError?: (message: string) => void;
  translation?: any;
}

const paymentMethodsContent: Record<PaymentMethods, PaymentMethodsContent> = {
  [PaymentMethods.Card]: {
    title: 'Credit Card',
    icon: (
      <div className={styles.cards}>
        <img src={Visa} alt="" />
        <img src={MasterCard} className={styles.mastercard} alt="" />
        <img src={Maestro} alt="" />
        <img src={Amex} className={styles.amex} alt="" />
      </div>
    ),
  },
  [PaymentMethods.PayPal]: {
    title: 'PayPal',
    icon: PayPalIcon,
  },
  [PaymentMethods.ApplePay]: {
    title: 'Apple Pay',
    icon: ApplePayIcon,
  },
};

export const PaymentsAccordion: FC<Props> = ({
  options,
  product,
  onError,
  onSuccess,
  translation,
}) => {
  const dispatch = useAppDispatch();
  const { paymentMethod } = useAppSelector((state) => state.user);

  const {
    handlePaymentMethodChange,
    setErrorMessage,
    errorMessage,
    isPaypalLoading,
  } = usePaymentsLogic({
    product,
    // @ts-ignore
    onSuccess,
    // @ts-ignore
    onError,
  });

  useEffect(() => {
    if (!paymentMethod) {
      dispatch(
        setPaymentMethod(
          isApplePayAvailable()
            ? PaymentMethods.ApplePay
            : PaymentMethods.PayPal,
        ),
      );
    }
  }, [dispatch, paymentMethod]);

  return (
    <>
      <Alert
        visible={!!errorMessage}
        error={errorMessage}
        onClose={() => setErrorMessage('')}
      />
      <div className={styles.container}>
        {[...options].reverse().map((option) => (
          <Item
            key={option}
            id={option}
            isActive={option === paymentMethod}
            title={paymentMethodsContent[option].title}
            icon={paymentMethodsContent[option].icon}
            onClick={() => {
              if (option) {
                dispatch(setPaymentMethod(option));
              }
            }}
          >
            <>
              {renderPaymentMethodItem({
                paymentMethod: option,
                handlePaymentMethodChange,
                isPaypalLoading,
                product,
                // @ts-ignore
                onSuccess,
                // @ts-ignore
                onError,
                translation,
              })}
              <RecurlyLogo />
            </>
          </Item>
        ))}
      </div>
    </>
  );
};

type RenderPaymentMethodItemProps = {
  paymentMethod?: PaymentMethods;
  handlePaymentMethodChange: (type: PaymentMethods) => void;
  isPaypalLoading: boolean;
  product: Product;
  onSuccess: (params: SuccessPaymentProps) => void;
  onError: (message: string) => void;
  translation: (key: string) => string;
};

const renderPaymentMethodItem = ({
  paymentMethod,
  handlePaymentMethodChange,
  isPaypalLoading,
  product,
  onSuccess,
  onError,
  translation,
}: RenderPaymentMethodItemProps): JSX.Element | JSX.Element[] | undefined => {
  switch (paymentMethod) {
    case PaymentMethods.ApplePay:
      return (
        <ApplePayElement
          isLoading={isPaypalLoading}
          onPay={() => handlePaymentMethodChange(PaymentMethods.ApplePay)}
        />
      );
    case PaymentMethods.PayPal:
      return (
        <PayPalElement
          isLoading={isPaypalLoading}
          onContinue={() => handlePaymentMethodChange(PaymentMethods.PayPal)}
        />
      );
    case PaymentMethods.Card:
      return (
        <CardFormWrapper
          product={product}
          onError={onError}
          onSuccess={onSuccess}
          translation={translation}
        />
      );
    default:
      return undefined;
  }
};
