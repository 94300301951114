import React from 'react';
import {
  isTrialExplainerWithHighlight,
  isHorizontalTrialExplainer,
  isTrialExplainerWithDate,
} from 'navigation';
import { Product } from 'bootstrap/data/product/Product';
import { TrialExplainerWithHeighlight } from './heighlight/HeighlightTrialExplainer';
import { TrialExplainerWithDate } from './withdate/TrialExplainerWithDate';
import { DefaultTrialExplainer } from './default/DefaultTrialExplainer';
import { HorizontalTrialExplainer } from './horizontal/HorizontalTrialExplainer';

type Props = {
  product?: Product;
};

export const TrialExplainer = ({ product }: Props) => {
  if (isTrialExplainerWithHighlight()) {
    return <TrialExplainerWithHeighlight />;
  }
  if (isTrialExplainerWithDate()) {
    return <TrialExplainerWithDate product={product} />;
  }
  if (isHorizontalTrialExplainer()) {
    return <HorizontalTrialExplainer />;
  }
  return <DefaultTrialExplainer />;
};
