import { getQueryVariable } from './utils';

export enum Type {
  CONGRATS = 'congrats',
  SKIP_TRIAL = 'st',
  PAYMENT_FORM = 'pf',
  PRODUCT = 'pr',
  PLAN = 'plan',
  SPECIAL_FLOW = 'sf',
  FACEBOOK_REVIEWS = 'reviews',
  GAD = 'gad',
  FAQ = 'faq',
  FREE_PAID = 'freeVsPaid',
  PERSONALIZATION = 'personalization',
  PERSONALIZATION_LOADER = 'opl_estimate',
  LOCAL_CURRENCY = 'loc',
  PRESALE_OFFER = 'ps_offer',
  BLITZ = 'blitz',
  SLEEP_ACTIVITY_LOADER_V2 = 'loader_v2',
  PRICE_BUTTON = 'priceButton',
  FEATURED_IN = 'featuredIn',
  TRIAL_EXPLAINER = 'trex',
  EVENT_POLICIES = 'ep',
  WELCOME_LOADER = 'welcome_loader',
  PREPLAN = 'preplan',
  MEQ_RESULTS = 'results',
  ANUMATION_SPEED_INCREASE = 'trans_speed_increase',
  WORKBOOK = 'workbook',
  VENMO = 'venmo',
  PREVENT_BACK = 'pb',
  PERSONALIZATIOND3 = 'personalizationD3',
  RELATE_SCALE_QUESTION = 'relsc',
  PAYMENT = 'payment',
  EDU = 'edu',
  ISI = 'isi',
}

export const isDefaultOnboarding = (): boolean =>
  getQueryVariable(Type.SPECIAL_FLOW) === 'onboarding';

export const isCongratsV2 = (): boolean =>
  getQueryVariable(Type.CONGRATS) === 'congrats_v2';

export const isIgnorePresaleFlow = (): boolean =>
  getQueryVariable(Type.PRESALE_OFFER) === 'false';

export const isIgnoreSkipTrialFlow = (): boolean =>
  getQueryVariable(Type.SKIP_TRIAL) === 'false';

export const isSkipTrialWithoutRefund = (): boolean =>
  getQueryVariable(Type.SKIP_TRIAL) === 'nr';

export const isSkipTrialV3 = (): boolean =>
  getQueryVariable(Type.SKIP_TRIAL) === 'v3';

export const isLayoutCardPaymentForm = (): boolean =>
  getQueryVariable(Type.PAYMENT_FORM) === 'cardlyt';

export const isPreselectProductOffer = (): boolean =>
  !!getQueryVariable(Type.PRODUCT);

export const isFacebookReviews = (): boolean =>
  getQueryVariable(Type.FACEBOOK_REVIEWS) === 'fb';

export const isGadTest = (): boolean => getQueryVariable(Type.GAD) === 'true';

export const isFaq = (): boolean => getQueryVariable(Type.FAQ) === 'true';

export const isFreeVsPaid = (): boolean =>
  getQueryVariable(Type.FREE_PAID) === 'true';

export const isLocalCurrencyEnabled = (): boolean =>
  getQueryVariable(Type.LOCAL_CURRENCY) === 'true';

export const isQuickPurchaseFlow = (): boolean =>
  window.location.pathname.split('/').includes('quick-purchase');

export const isInviteFlow = (): boolean =>
  window.location.pathname.split('/').includes('invite');

export const isGiftFlow = (): boolean =>
  window.location.pathname.split('/').includes('gift');

export const isRedeemFlow = (): boolean =>
  window.location.pathname.split('/').includes('redeem');

export const isSubscriptionCancelFlow = (): boolean =>
  window.location.pathname.split('/').includes('subscription-cancel');

export const isBlitzV2Flow = (): boolean =>
  getQueryVariable(Type.BLITZ) === 'anx';

export const isSleepActivityLoaderV2 = (): boolean =>
  getQueryVariable(Type.SLEEP_ACTIVITY_LOADER_V2) === 'true';

export const isPresalePulsingButtonFlow = (): boolean =>
  getQueryVariable(Type.PRICE_BUTTON) === 'animated';

export const isFeaturedInFlow = (): boolean =>
  getQueryVariable(Type.FEATURED_IN) === 'true';

export const isTrialExplainerWithDate = (): boolean =>
  getQueryVariable(Type.TRIAL_EXPLAINER) === 'dt';

export const isTrialExplainerWithHighlight = (): boolean =>
  getQueryVariable(Type.TRIAL_EXPLAINER) === 'highlight';

export const isHorizontalTrialExplainer = (): boolean =>
  getQueryVariable(Type.TRIAL_EXPLAINER) === 'horizontal';

export const isFlexibleOmitViewEventsFlow = (): boolean =>
  getQueryVariable(Type.EVENT_POLICIES) === 'fov';

export const isWelcomeLoaderFlow = (): boolean =>
  getQueryVariable(Type.WELCOME_LOADER) === 'true';

export const isPreplanFlow = (): boolean =>
  getQueryVariable(Type.PREPLAN) === 'true';

export const isMeqResultV2Flow = (): boolean =>
  getQueryVariable(Type.MEQ_RESULTS) === 'ext';

export const isMeqResultAnimalFlow = (): boolean =>
  getQueryVariable(Type.MEQ_RESULTS) === 'anim';

export const isAnimationSpeedFlow = (): boolean =>
  getQueryVariable(Type.ANUMATION_SPEED_INCREASE) === 'true';

export const isWorkbookUpsellFlow = (): boolean =>
  getQueryVariable(Type.WORKBOOK) === 'ups';

export const isShortWebCancelFlow = (): boolean =>
  getQueryVariable('type') === 'short';

export const isPaymentPreventBack = (): boolean =>
  getQueryVariable(Type.PREVENT_BACK) === 'payment';

export const isRelateScaleQuestion = (): boolean =>
  getQueryVariable(Type.RELATE_SCALE_QUESTION) === 'true';

export const isPersonalizationLoaderEstimateFlow = (): boolean =>
  getQueryVariable(Type.PERSONALIZATION_LOADER) === 'true';

export const isPaymentPlanSelectorDirect = (): boolean => {
  return getQueryVariable(Type.PAYMENT) === 'plan-selector-ramp-direct';
};

export const isEducationV1Quiz = (): boolean =>
  getQueryVariable(Type.EDU) === 'v1';

export const isEducationV2Quiz = (): boolean =>
  getQueryVariable(Type.EDU) === 'v2';

export const isEducationV3Quiz = (): boolean =>
  getQueryVariable(Type.EDU) === 'v3';

export const isInsomniaV1Quiz = (): boolean =>
  getQueryVariable(Type.ISI) === 'v1';

export const isInsomniaV2Quiz = (): boolean =>
  getQueryVariable(Type.ISI) === 'v2';
