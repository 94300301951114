import React from 'react';
import ExplainerItem from '../components/explaineritem/Item';
import { ReactComponent as UnLock } from 'assets/stepper/unlock.svg';
import { ReactComponent as Bell } from 'assets/stepper/bell.svg';
import { ReactComponent as Checked } from 'assets/stepper/check.svg';
import { ReactComponent as Star } from 'assets/stepper/star.svg';
import explainerItemStyles from '../components/explaineritem/styles.module.scss';
import { Product } from 'bootstrap/data/product/Product';
import { ExplainerWrapper } from '../components/explainerwrapper/Wrapper';

type Props = {
  product?: Product;
};

export const TrialExplainerWithDate = ({ product }: Props) => {
  const trialStartAfterFiveDaysDate = product?.getTrialFormattedDateWithWeekDay(
    product.getTrialAfterFiveDaysDate(),
  );
  const trialEndDate = product?.getTrialFormattedDateWithWeekDay(
    product.getTrialEndDate(),
  );

  return (
    <ExplainerWrapper>
      <ExplainerItem
        className={explainerItemStyles.check}
        icon={<Checked />}
        title="stepperTitleStep1"
        description="stepperDescriptionStep1"
      />
      <ExplainerItem
        className={explainerItemStyles.lock}
        icon={<UnLock />}
        title="stepperTitleStep2"
        description="stepperDescriptionStep2"
      />
      <ExplainerItem
        className={explainerItemStyles.bell}
        icon={<Bell />}
        title={trialStartAfterFiveDaysDate}
        description="stepperDescriptionStep3"
      />
      <ExplainerItem
        className={explainerItemStyles.star}
        icon={<Star />}
        title={trialEndDate}
        description="stepperDescriptionStep4"
      />
    </ExplainerWrapper>
  );
};
