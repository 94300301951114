import { useEffect, useState } from 'react';
import { appAnalytics } from 'analytics';
import { ScreenOpened } from 'analytics/AllEvents';
import { useAppDispatch, useAppSelector } from 'bootstrap/hooks';
import { hasAllRmeqTestAnswers } from 'bootstrap/onboarding/actions';
import {
  emailLogin,
  registerNoPassword,
  setCountryCode,
  setCountryCurrency,
  setPlanCode,
  setRestrictMailing,
} from 'bootstrap/user/actions';
import {
  isIgnorePresaleFlow,
  isLocalCurrencyEnabled,
  isPaymentPlanSelectorDirect,
  isPreplanFlow,
  isPreselectProductOffer,
} from 'navigation';
import {
  getCircadianType,
  getRmeqTestResult,
} from 'onboarding/question/meqresults/MeqResults.hook';
import { useTranslation } from 'react-i18next';
import { pushWithSearchQuery, validateEmail } from 'utils';

export const useSignInLogic = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const {
    isRestrictMailing,
    planCode = '',
    countryCode,
  } = useAppSelector((state) => state.user);
  const { answers } = useAppSelector((state) => state.onboarding);

  const rmeqQuizResult = hasAllRmeqTestAnswers(answers)
    ? getCircadianType(getRmeqTestResult(answers))
    : '';

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [emailFocused, setEmailFocused] = useState(false);

  useEffect(() => {
    if (!planCode) {
      const codeNumber = Math.floor(Math.random() * 90000) + 10000;
      dispatch(setPlanCode(`#LN-${codeNumber}`));
    }
    dispatch(setCountryCode());

    if (isLocalCurrencyEnabled()) {
      dispatch(setCountryCurrency());
    }
    appAnalytics.trackEvent(new ScreenOpened('onboardingAccount'));
  }, []);

  const handleSubmit = async (): Promise<void> => {
    setIsErrorVisible(false);

    if (isLoading && !validateForm()) return;

    setIsLoading(true);

    let action;

    if (password !== '') {
      action = emailLogin(email, password, 'qp');
    } else if (rmeqQuizResult) {
      action = registerNoPassword(
        email,
        planCode,
        isRestrictMailing,
        rmeqQuizResult,
      );
    } else {
      action = registerNoPassword(email, planCode, isRestrictMailing);
    }

    try {
      const hasSubscription = await dispatch(action);
      setIsLoading(false);
      navigateNext(hasSubscription);
    } catch (error: any) {
      setIsLoading(false);

      if (error?.email) {
        setEmailError(t('emailError'));
      } else if (error?.password || error === 'WRONG_PASSWORD') {
        setPasswordError(t('passwordError'));
      } else if (error === 'DATA_EXIST') {
        setIsPasswordVisible(true);
      } else {
        setErrorMessage(error);
        setIsErrorVisible(true);
      }
    }
  };

  const navigateNext = (hasSubscription: boolean) => {
    if (hasSubscription) {
      dispatch(pushWithSearchQuery('/done'));
    } else if (isPreplanFlow()) {
      dispatch(pushWithSearchQuery('/preplan'));
    } else if (
      isPreselectProductOffer() ||
      isIgnorePresaleFlow() ||
      isPaymentPlanSelectorDirect()
    ) {
      dispatch(pushWithSearchQuery('/subscription'));
    } else {
      dispatch(pushWithSearchQuery('/plan'));
    }
  };

  const validateForm = (): boolean => {
    const isEmailValid = validateEmail(email);
    const isPasswordValid = password !== '';
    if (!isEmailValid) {
      setEmailError(t('emailError'));
      return false;
    } else if (isPasswordVisible && !isPasswordValid) {
      setPasswordError(t('passwordError'));
      return false;
    } else return true;
  };

  const onEmailChange = (email: string) => {
    setEmailError('');
    setEmail(email);
    setIsErrorVisible(false);
  };

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordError(
      event.target.value.length < 8 ? t('passwordLengthError') : '',
    );
    setPassword(event.target.value);
    setIsErrorVisible(false);
  };

  const onHandleCheckboxClick = () => {
    dispatch(setRestrictMailing(!isRestrictMailing));
  };

  const onEmailFocusHandler = () => setEmailFocused(true);

  const onEmailBlurHandler = () => setEmailFocused(false);

  const onErrorVisibleHandler = () => setIsErrorVisible(false);

  return {
    emailError,
    email,
    emailFocused,
    passwordError,
    password,
    isErrorVisible,
    isLoading,
    errorMessage,
    planCode,
    countryCode,
    isPasswordVisible,
    isRestrictMailing,
    validateForm,
    navigateNext,
    handleSubmit,
    onHandleCheckboxClick,
    onEmailFocusHandler,
    onEmailBlurHandler,
    onPasswordChange,
    onEmailChange,
    onErrorVisibleHandler,
  };
};
