import React from 'react';
import styles from './styles.module.scss';

export const HorizontalTrialExplainer = () => {
  return (
    <div className={styles.explainerWrapper}>
      <div className={styles.greenExplainerWrapper}>
        <div className={styles.dateWrapper}>
          <div className={styles.startDayLabel}>TODAY</div>
          <div className={styles.endDayLabel}>DAY 7</div>
        </div>
        <div className={styles.explainer}>CANCEL ANYTIME</div>
        <span className={styles.borderText}>1-WEEK TRIAL</span>
      </div>
      <div className={styles.whiteExplainerWrapper} />
    </div>
  );
};
