export enum QuestionIds {
  Welcome = 'Welcome',
  AboutYouGuided = 'AboutYouGuided',
  AboutYouD1 = 'AboutYouD1',
  AboutYouGender = 'AboutYouGender',
  AboutYouAge = 'AboutYouAge',
  AboutYouRoutine = 'AboutYouRoutine',
  AboutYouD2 = 'AboutYouD2',
  AboutYouKids = 'AboutYouKids',
  AboutYouD3 = 'AboutYouD3',
  AboutYouActivity = 'AboutYouActivity',
  AboutYouD4 = 'AboutYouD4',
  AboutYouProof1 = 'AboutYouProof1',

  RMeqQ1 = 'RMeqQ1',
  RMeqQ2 = 'RMeqQ2',
  RMeqQ3 = 'RMeqQ3',
  RMeqQ4 = 'RMeqQ4',
  RMeqQ5 = 'RMeqQ5',
  MeqResults = 'MeqResults',

  EduD1 = 'EduD1',
  EduQ1 = 'EduQ1',
  EduQ1Answer = 'EduQ1Answer',
  EduQ2 = 'EduQ2',
  EduQ2Answer = 'EduQ2Answer',
  EduQ3 = 'EduQ3',
  EduQ3Answer = 'EduQ3Answer',
  EduQ4 = 'EduQ4',
  EduQ4Answer = 'EduQ4Answer',
  EduQ5 = 'EduQ5',
  EduQ5Answer = 'EduQ5Answer',

  GadQ1 = 'GadQ1',
  GadQ2 = 'GadQ2',
  GadQ3 = 'GadQ3',
  GadQ4 = 'GadQ4',
  GadQ5 = 'GadQ5',
  GadQ6 = 'GadQ6',
  GadQ7 = 'GadQ7',
  GadResults = 'GadResults',

  IsiQ1 = 'IsiQ1',
  IsiQ2 = 'IsiQ2',
  IsiQ3 = 'IsiQ3',
  IsiQ4 = 'IsiQ4',
  IsiQ5 = 'IsiQ5',
  IsiD1 = 'IsiD1',

  SleepAndActivityQ1 = 'SleepAndActivityQ1',
  SleepAndActivityQ2 = 'SleepAndActivityQ2',
  SleepAndActivityD1 = 'SleepAndActivityD1',
  SleepAndActivityQ3 = 'SleepAndActivityQ3',
  SleepAndActivityProof1 = 'SleepAndActivityProof1',
  SleepAndActivityProof2 = 'SleepAndActivityProof2',
  SleepAndActivityCoffee = 'SleepAndActivityCoffee',
  SleepAndActivityDinner = 'SleepAndActivityDinner',
  SleepAndActivityAfterMidnight = 'SleepAndActivityAfterMidnight',
  SleepAndActivityLessThanEightHours = 'SleepAndActivityLessThanEightHours',
  SleepAndActivityExtraMotivation = 'SleepAndActivityExtraMotivation',
  SleepAndActivityD2 = 'SleepAndActivityD2',
  SleepAndActivityLoader = 'SleepAndActivityLoader',

  PersonalizationD1 = 'PersonalizationD1',
  PersonalizationColors1 = 'PersonalizationColors1',
  PersonalizationColors2 = 'PersonalizationColors2',
  PersonalizationColors3 = 'PersonalizationColors3',
  PersonalizationColors4 = 'PersonalizationColors4',
  PersonalizationProof1 = 'PersonalizationProof1',
  PersonalizationArtwork1 = 'PersonalizationArtwork1',
  PersonalizationArtwork2 = 'PersonalizationArtwork2',
  PersonalizationArtwork3 = 'PersonalizationArtwork3',
  PersonalizationArtwork4 = 'PersonalizationArtwork4',
  PersonalizationNarrative = 'PersonalizationNarrative',
  PersonalizationLoader = 'PersonalizationLoader',
  PersonalizationD2 = 'PersonalizationD2',
  PersonalizationD3 = 'PersonalizationD3',
  PersonalizationGoal = 'PersonalizationGoal',

  PersonalizationLength = 'PersonalizationLength',
  PersonalizationFrequency = 'PersonalizationFrequency',
  PersonalizationFeelingHoping = 'PersonalizationFeelingHoping',
  PersonalizationSounds = 'PersonalizationSounds',
  PersonalizationPlaces = 'PersonalizationPlaces',
  PersonalizationFeeling = 'PersonalizationFeeling',

  IntroSignInLoader = 'IntroSignInLoader',

  BlitzArt = 'BlitzArt',
  BlitzDaydream = 'BlitzDaydream',
  BlitzTinnitus = 'BlitzTinnitus',
  BlitzRead = 'BlitzRead',
  BlitzShiftWork = 'BlitzShiftWork',
  BlitzADHD = 'BlitzADHD',
}
