import React from 'react';
import { CookieConsent } from 'onboarding/components/cookieconsent/CookieConsent';
import WelcomeGadImage from 'assets/welcome/welcome_gad.png';
import { Footer } from 'welcome/components/footer/Footer';
import { useTranslation } from 'react-i18next';
import { useGadWelcomeLogic } from './GadWelcome.hook';
import styles from './styles.module.scss';

const GadWelcome = () => {
  const { t } = useTranslation();
  const { handleTakeTest } = useGadWelcomeLogic();

  return (
    <div className={styles.wrapper}>
      <img src={WelcomeGadImage} className={styles.image} alt="" />
      <div className={styles.content}>
        <div className={styles.title}>
          {t('welcomeGadTitleLine1')}
          <span className={styles.accent}>{t('welcomeGadTitleLine2')}</span>
        </div>

        <div className={styles.subtitle}>{t('welcomeGadSubtitle')}</div>

        <button className={styles.button} onClick={handleTakeTest}>
          {t('welcomeRmeqAndGadContinueButton')}
        </button>

        <Footer />
      </div>
      <CookieConsent />
    </div>
  );
};

export default GadWelcome;
