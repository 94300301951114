import i18next from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

type Props = {
  icon?: JSX.Element | JSX.Element[];
  title?: string;
  description: string;
  className: string;
};

const ExplainerItem = ({ icon, title, description, className }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={`${styles.step} ${className}`}>
      <div className={styles.iconWrapper}>
        {icon && <div className={styles.icon}>{icon}</div>}
        <div className={styles.line} />
      </div>
      <div className={styles.textWrapper}>
        {title && i18next.exists(title) ? (
          <div className={styles.title}>{t(title)}</div>
        ) : (
          <div className={styles.title}>{title}</div>
        )}
        <div className={styles.description}>{t(description)}</div>
      </div>
    </div>
  );
};

export default ExplainerItem;
