import React from 'react';
import { Product } from 'bootstrap/data/product/Product';
import Alert from 'components/error/Error';
import { LoadingScreen } from 'components/loadingscreen/LoadingScreen';
import { useTranslation } from 'react-i18next';
import { usePaymentsLogic } from 'subscription/recurly/components/paymentpicker/PaymentsPicker.hook';
import { SuccessPaymentProps } from 'subscription/recurly/Recurly.types';
import { PaymentsAccordion } from 'subscription/components/Payments/accordion/PaymentsAccordion';
import { isGiftFlow, isInviteFlow } from 'navigation';
import { PaymentMethods } from 'subscription/components/Payments/Payments.types';
import { Picker } from 'subscription/components/picker/Picker';

type Props = {
  product: Product;
  darkTheme?: boolean;
  isPaypalLoading?: boolean;
  onSuccess: (params: SuccessPaymentProps) => void;
  onError: (message: string) => void;
  translation?: any;
  children?: JSX.Element | JSX.Element[];
};

export const PaymentsPicker = ({
  product,
  darkTheme = false,
  onSuccess,
  onError,
  translation,
  children,
}: Props) => {
  const { t } = useTranslation();

  const {
    pickerOptions,
    errorMessage,
    isPaypalLoading,
    isPaypalReady,
    handlePaymentMethodChange,
    setErrorMessage,
  } = usePaymentsLogic({
    product,
    darkTheme,
    onSuccess,
    onError,
  });

  return (
    <>
      <LoadingScreen
        darkTheme={darkTheme}
        isVisible={!isPaypalReady || isPaypalLoading}
        title="Waiting for PayPal..."
        description={
          <>
            {t('loadingScreenDescriptionLine1')}
            <br />
            {t('loadingScreenDescriptionLine2')}
          </>
        }
      />
      <Alert
        visible={!!errorMessage}
        error={errorMessage}
        onClose={() => setErrorMessage('')}
      />
      {isGiftFlow() || isInviteFlow() ? (
        <>
          <Picker
            options={pickerOptions}
            currentOption={PaymentMethods.Card}
            darkTheme={darkTheme}
            onChange={handlePaymentMethodChange}
          />
          {children}
        </>
      ) : (
        <PaymentsAccordion
          product={product}
          options={pickerOptions}
          translation={translation}
          onSuccess={onSuccess}
          onError={onError}
        >
          {children}
        </PaymentsAccordion>
      )}
    </>
  );
};
